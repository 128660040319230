<template>
  <div id="QuestionWrapper" class="w-100 tlw-bg-white" :class="boxMode ? 'hs-box-shadow' : ''">
    <div class="d-flex flex-column" :class="boxMode ? 'p-3' : ''">
      <span class="font-weight-bold">
        {{ question.text }}
      </span>
      <span class="mt-2 hs-text-14">
        {{ question.multiselect ? $t('sign_up.questionary.multiselect') : '' }}
      </span>
    </div>
    <div class="d-flex flex-column" :class="boxMode ? 'px-3 py-3' : ''">
      <div
        v-for="(option, index) in actualStep && question.options"
        :key="option.value"
        class="d-flex w-100 align-items-center form-control my-2 h-auto hs-questions-wrapper_item"
        :class="(option.selected && 'bd-purple') || ''"
      >
        <hs-checkbox
          data-testid="question-checkbox"
          v-if="question.multiselect"
          v-model="option.selected"
          @input="$emit('selected', { index: actualStep - 1, option })"
          class="w-100"
        >
          <span v-if="option.type !== 'input' || !option.selected" :class="option.selected && 'font-weight-bold'">
            {{ option.title }}
          </span>
          <div class="col-xs-12 col-sm-6 px-0" v-if="option.selected && option.type === 'input'">
            <hs-input
              @blur="$v.question.options.$each[index].inputValue.$touch()"
              :state="!$v.question.options.$each[index].inputValue.$error ? null : false"
              :placeholder="option.inputPlaceholder"
              v-model="option.inputValue"
              autofocus
            >
              <template slot="feedback" v-if="$v.question.options.$each[index].inputValue.$error">
                <hs-form-invalid-feedback :state="false">
                  {{ $t('validations.required') }}
                </hs-form-invalid-feedback>
              </template>
            </hs-input>
          </div>
        </hs-checkbox>
        <div class="w-100" @click.once="option.selected && $emit('selected', { index: actualStep - 1, option })" v-else>
          <hs-radio
            @change="$emit('selected', { index: actualStep - 1, option })"
            v-model="question.selectedOption"
            :value="option.value"
            data-testid="question-radio"
          >
            <span v-if="option.type !== 'input' || !option.selected" :class="option.selected && 'font-weight-bold'">
              {{ option.title }}
            </span>
            <div class="col-xs-12 col-sm-6 px-0" v-if="option.selected && option.type === 'input'">
              <hs-input
                @blur="$v.question.options.$each[index].inputValue.$touch()"
                :state="!$v.question.options.$each[index].inputValue.$error ? null : false"
                :placeholder="option.inputPlaceholder"
                v-model="option.inputValue"
                autofocus
              >
                <template slot="feedback" v-if="$v.question.options.$each[index].inputValue.$error">
                  <hs-form-invalid-feedback :state="false">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </div>
          </hs-radio>
          <div v-if="option.moreQuestion && option.selected">
            <form>
              <ul>
                <li v-for="item in option.moreQuestion" :key="item.question">
                  <p class="text-bold font-weight-bold mt-2">{{ item.question }}</p>
                  <hs-input
                    class="ml-5"
                    v-if="item.type === 'String'"
                    :name="item.name"
                    v-model="question.replyMoreQuestions[item.name]"
                    @blur="$v.question.replyMoreQuestions[item.name].$touch()"
                    :state="!$v.question.replyMoreQuestions[item.name].$error ? null : false"
                    data-testid="question-moreQuestion"
                  >
                  </hs-input>
                  <div v-else class="d-flex aling-items-center ml-5">
                    <label for="">Sim</label>
                    <hs-radio
                      :name="item.name"
                      v-model="question.replyMoreQuestions[item.name]"
                      @blur="$v.question.replyMoreQuestions[item.name].$touch()"
                      :state="!$v.question.replyMoreQuestions[item.name].$error ? null : false"
                      value="true"
                      data-testid="question-moreQuestion"
                    ></hs-radio>
                    <label for="">Não</label>
                    <hs-radio
                      :name="item.name"
                      v-model="question.replyMoreQuestions[item.name]"
                      @blur="$v.question.replyMoreQuestions[item.name].$touch()"
                      :state="!$v.question.replyMoreQuestions[item.name].$error ? null : false"
                      value="false"
                      data-testid="question-moreQuestion"
                    ></hs-radio>
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    question: Object,
    actualStep: Number,
    moreQuestion: Object,
    replyMoreQuestions: Object,
    boxMode: {
      type: Boolean,
      default: true,
    },
  },
  validations: {
    question: {
      options: {
        $each: {
          inputValue: {
            required,
          },
        },
      },
      replyMoreQuestions: {
        want_to_back: {
          required,
        },
        what_missed: {
          required,
        },
        what_choose_another_platform: {
          required,
        },
        what_errors_impacted_project: {
          required,
        },
        what_biggest_difficulty: {
          required,
        },
        have_interest_mkt: {
          required,
        },
        reason_other: {
          required,
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ #QuestionWrapper {
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  .custom-control-input:hover ~ .custom-control-label:before {
    border-color: $purple-dark !important;
  }
  .custom-control-input:not(:focus) ~ .custom-control-label:before {
    border: 1px solid #adb5bd;
  }
  .custom-control-input:active ~ .custom-control-label:before {
    background-color: transparent;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
}
.hs-questions-wrapper_item:hover {
  border-color: $purple-dark;
}
.bd-purple {
  border-color: $purple-dark;
}
</style>
