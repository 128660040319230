<template>
  <div>
    <button
      type="button"
      :tabindex="clickable ? 0 : -1"
      class="hs-stepper__button d-flex justify-content-center align-items-center font-weight-bold rounded-circle border position-relative"
      :class="stepClass"
      @click="onClick"
      :style="stepStyle"
    >
      <hs-icon v-if="completed" variant="light" icon="check" />
      <span v-else>
        {{ text }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    clickable: Boolean,
    focusable: Boolean,
    activated: Boolean,
    completed: Boolean,
    onClick: {
      type: Function,
      default: () => {},
    },
    size: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    stepClass() {
      const isDeactived = !this.activated && !this.completed;
      let classes = this.focusable ? 'hs-stepper__focusable ' : 'pointer-none ';
      classes += this.activated ? ' hs-stepper__active border-dark' : '';
      classes += !this.activated && this.completed ? ' hs-stepper__complete badge-primary border-purple' : '';
      classes += isDeactived ? ' hs-stepper__deactive' : '';
      return classes;
    },
    stepStyle() {
      let size = 28;
      let fontSize = 1;
      if (this.size === 'small') {
        size = 22;
        fontSize = 0.75;
      }
      return {
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${fontSize}rem`,
      };
    },
  },
};
</script>

<style lang="scss">
.hs-stepper__active {
  border-width: 2px !important;
  background: #ffffff;
  color: #7427f1;
  border-color: #7427f1 !important;
}
.hs-stepper__deactive {
  border-width: 2px !important;
  background: $grey-10;
  color: $grey-25;
  border-color: $grey-25 !important;
}

.hs-stepper__button {
  outline: none !important;
  width: 28px;
  height: 28px;
  transition: all 0.15s ease;
}
.border-purple {
  background: #7427f1 !important;
  border-color: #7427f1 !important;
}
.hs-stepper__focusable:hover,
.hs-stepper__focusable:focus {
  transform: scale(1.2);
}
</style>
