<template>
  <div class="flex flex-column">
    <h1 class="font-weight-bold mb-3 pb-1">{{ $t('sign_up.questionary.header.title') }}</h1>
    <h5 class="mb-4 lh-150">
      {{ $t('sign_up.questionary.header.subtitle') }}
      <span class="font-weight-bold">
        {{ $t('sign_up.questionary.header.duration') }}
      </span>
    </h5>
  </div>
</template>

<script>
export default {};
</script>
