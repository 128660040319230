<template>
  <div>
    <StepQuestionary :boxModel="true" :showSkipButton="true" :product="getUserProduct" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StepQuestionary from '@/auth/views/SignUp/components/StepQuestionary/index.vue';

export default {
  components: { StepQuestionary },
  computed: {
    ...mapGetters('school', ['hasFeature']),
    getUserProduct() {
      return this.hasFeature('spark_members') ? 'sparkmembers' : 'sparkfunnels';
    },
  },
};
</script>
