<template>
  <div
    class="hs-questionary d-flex flex-column mt-3"
    :class="{ 'align-items-center': boxModel }"
    :style="{
      marginBottom: isLoading ? 'none' : 'auto',
    }"
  >
    <hs-loading v-if="isLoading" />
    <div :class="{ 'bg-white py-4 px-4 mt-5 hs-step-questionary__wrapper': boxModel }">
      <QuestionaryHeader class="d-none d-sm-block" v-if="!isLoading" />
      <QuestionaryMobileInitial
        v-if="!isLoading"
        v-model="initialVisible"
        :actual="actual"
        :illustration="illustration"
      />
      <QuestionaryBody
        :steps="steps"
        :actual="actual"
        :questions="questions"
        class="d-flex d-sm-none flex-column justify-content-end"
        v-if="!initialVisible && !isLoading"
        @selected="onOptionSelected"
        @step="onStepChange"
      />
      <QuestionaryBody
        v-if="!isLoading"
        :steps="steps"
        :actual="actual"
        :questions="questions"
        class="d-none d-sm-flex flex-column justify-content-end"
        @selected="onOptionSelected"
        @step="onStepChange"
      />
      <div class="d-flex justify-content-end" v-if="showSkipButton">
        <hs-button
          v-if="actual == 1"
          id="skip-button-desktop"
          class="mt-3 d-none d-sm-block"
          @click="skippedQuestionary()"
          variant="outline-secondary"
          type="button"
          >{{ $t('questionary_page.buttons.skip') }}
        </hs-button>
        <hs-button
          v-if="actual == 1"
          id="skip-button-mobile"
          class="mt-3 d-sm-none w-100"
          @click="skippedQuestionary()"
          variant="link"
          type="button"
          >{{ $t('questionary_page.buttons.skip') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import debug from 'debug';
import { mapMutations, mapActions, mapState } from 'vuex';
import { hsLoading } from '@/components';
import questionaryService from '@/services/questionary';
import { analyticsService, hubspotService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import QuestionaryBody from './QuestionaryBody.vue';
import QuestionaryHeader from './QuestionaryHeader.vue';
import QuestionaryMobileInitial from './QuestionaryMobileInitial.vue';
import inviteService from '@/sparkaffiliates/services/invite';
import { SparkProducts } from '@/types/spark_products';
import Features from '@/types/features';

const logging = debug('hs:sign-up-questionairy');

export default {
  components: { QuestionaryHeader, QuestionaryBody, QuestionaryMobileInitial, hsLoading },
  props: {
    product: {
      type: String,
      required: true,
    },
    boxModel: {
      type: Boolean,
    },
    showSkipButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    illustration() {
      return this.product === 'sparkfunnels'
        ? require('@/assets/images/funnel/funnels_illustration.svg')
        : require('@/assets/images/sign_up/illustration-signup-video.svg');
    },
    ...mapState('school', ['selectedSchool']),
  },
  data() {
    const questions = questionaryService.getQuestions();
    return {
      isLoading: false,
      initialVisible: true,
      steps: questions.map((_, index) => ({ id: index + 1 })),
      actual: 1,
      questions,
    };
  },
  methods: {
    ...mapMutations('school', ['addFeature']),
    ...mapActions('school', ['updateSchool', 'enableFeature']),
    onStepChange(step) {
      if (step === this.questions.length + 2) {
        this.onFinish();
      } else {
        this.goTo(step);
      }
    },
    goTo(step) {
      this.actual = step;
    },
    onOptionSelected({ index: questionIndex, option, isMultiselect, showNextButton }) {
      if (!this.questions[questionIndex].multiselect) {
        option.selected = true;
      }
      if (questionIndex === 1) {
        const newOptions = questionaryService.buildStandartOptions(
          option.id === 1 ? questionaryService.options1ToQuestion3 : questionaryService.options2ToQuestion3
        );
        const selectedValue = this.questions[2].selectedOption;
        const found = newOptions.find(option => option.value === selectedValue);
        if (!found) {
          this.questions[2].selectedOption = '';
          this.questions[2].options = newOptions;
        }
      }
      if (!isMultiselect) {
        if (option.selected) {
          this.questions[questionIndex].options.forEach(opt => {
            if (opt.selected && opt.id !== option.id) {
              opt.selected = false;
            }
          });
        }
        if (!showNextButton) {
          setTimeout(() => {
            this.goTo(this.actual + 1);
          }, 200);
        }
      }
    },
    async onFinish() {
      try {
        this.isLoading = true;

        const questions = questionaryService.normalizeQuestions(this.questions);
        const planReferral = window.sessionStorage.getItem('plan-referral') || null;
        await this.updateSchool({
          id: this.$store.state.school.selectedSchool.id,
          extra_settings: {
            questionary_filled: true,
            questionary_skipped: false,
            show_top_bar: true,
          },
        });
        hubspotService.sendQuestionnaire(
          questions,
          this.$store.state.school.selectedSchool.id,
          this.$store.state.school.selectedSchool.name,
          this.$store.state.school.selectedSchool.email,
          planReferral
        );
        analyticsService.identify(undefined, questions);
        analyticsService.track({
          event: 'Questionary Filled',
          props: {
            schoolId: this.$store.state.school.selectedSchool.id,
            schoolName: this.$store.state.school.selectedSchool.name,
            ...questions,
          },
        });
        window.sessionStorage.removeItem('plan-referral');

        let redirectTo = { name: 'HomePage' };

        const invite = window.sessionStorage.getItem('invite-code');

        if (invite) {
          const { inviteCode, product } = JSON.parse(invite);

          await inviteService.acceptInvite(
            inviteCode,
            this.selectedSchool.account_type,
            this.selectedSchool.id,
            this.selectedSchool.email,
            this.selectedSchool.name
          );

          let enableFeature;

          if (product === 'sparkaffiliates') {
            enableFeature = Features.SPARK_AFFILIATES;
            redirectTo = { ...this.$route, name: 'MyAffiliations' };
          } else if (product === 'coproduction') {
            enableFeature = Features.COPRODUCTOR;
            redirectTo = { ...this.$route, name: 'ProductsList', params: { goToCoprodutions: 'true' } };
          }

          enableFeature && this.addFeature(enableFeature);

          window.sessionStorage.removeItem('invite-code');
        }

        this.$router.push(redirectTo);
      } catch (e) {
        logging('failed sign-up', e);
        ToastHelper.dangerMessage(this.$t(`sign_up.questionary.toast.error`));
      } finally {
        this.isLoading = true;
      }
    },
    skippedQuestionary() {
      this.$router.push({ name: 'HomePage' });
    },
  },
};
</script>

<style lang="scss">
.hs-step-questionary__wrapper {
  max-width: 650px;
}
.hs-questionary .custom-control-label {
  width: 100%;
  padding: 0.3rem;
  cursor: pointer;
}
.hs-questionary .custom-control-label::before,
.hs-questionary .custom-control-label::after {
  margin-top: 0.3rem;
}
@media screen and (max-width: $screen-xs) {
  .hs-questionary__btn-back {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 5% !important;
  }
}

@media screen and (min-width: $screen-xs) {
  .hs-questionary__btn-back {
    padding-top: auto;
    position: relative;
  }
}

.hs-questionary__initial-bg {
  background-position: 66% 70%, bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: calc(100vh - 396px);
  width: 100%;
  left: 0px;
  bottom: 0px;
  z-index: -1;
}
</style>
