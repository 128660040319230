<template>
  <div>
    <div class="hs-box-shadow">
      <div class="d-flex position-relative bg-info-light p-3 ">
        <div class="d-flex flex-column col-12 col-sm-8 px-0 ">
          <span class="font-weight-bold">{{ $t('sign_up.questionary.complete.title') }}</span>
          <span class="mt-2 hs-text-14">
            {{ $t('sign_up.questionary.complete.subtitle') }}
          </span>
        </div>
        <div class="position-relative d-flex justify-content-center col-xs-12 col-sm-4 px-0">
          <img
            width="160"
            class="d-none d-sm-block position-absolute"
            :src="headerIllustration"
            alt="questionary-finished"
          />
        </div>
      </div>
      <div class="tlw-bg-white d-flex d-sm-none justify-content-center align-items-center w-100 py-4">
        <img width="200" :src="headerIllustration" alt="questionary-finished" />
      </div>

      <div class="d-none d-sm-block py-3 px-0 px-sm-3">
        <hs-button
          data-testid="questionary-finish-button"
          class="col-xs-12 col-sm-auto"
          variant="primary"
          @click="() => $emit('next')"
        >
          {{ $t('sign_up.questionary.finish') }}
        </hs-button>
      </div>
    </div>
    <div class="d-sm-none py-2 px-0 px-sm-3">
      <hs-button class="col-xs-12 col-sm-auto" variant="primary" @click="() => $emit('next')">
        {{ $t('sign_up.questionary.finish') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerIllustration: require('@/assets/images/sign_up/brain-celebration.svg'),
    };
  },
};
</script>
