<template>
  <div>
    <div class="hs-stepper__progress-wrapper px-2 position-absolute w-100">
      <hs-progress class="hs-stepper__progress w-100 badge-primary" height="8px" :value="progress" />
    </div>
    <nav class="d-flex position-relative mb-2">
      <div
        v-for="(step, index) in steps"
        :key="step.id"
        class="d-flex align-items-center position-relative"
        :style="{
          width: getStepWrapperWidth(index),
        }"
      >
        <div class="d-flex align-items-center">
          <Step
            :text="step.id + ''"
            :clickable="isStepClickable(step)"
            :focusable="isStepFocusable(step)"
            :activated="isStepActived(step)"
            :completed="isStepCompleted(step)"
            :onClick="() => onStepClick(step)"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Step from './Step';
export default {
  components: { Step },
  props: {
    steps: Array,
    actual: Number,
    completed: Array,
  },
  data() {
    return {
      progress: 0,
    };
  },
  methods: {
    onStepClick(step) {
      if (step.id < this.actual) {
        this.$emit('jump', step.id);
      }
    },
    getStepWrapperWidth(index) {
      if (index === this.steps.length - 1) {
        return 'auto';
      } else if (this.steps.length <= 1) {
        return '100%';
      }
      return `${100 / (this.steps.length - 1)}%`;
    },
    isStepFocusable(step) {
      return step.id <= this.lastCompletedId + 1;
    },
    isStepActived(step) {
      return step.id === this.actual;
    },
    isStepClickable(step) {
      for (let i = 0; i < step.id - 1; i++) {
        const isCompleted = !!this.completed.find(stepC => stepC.id === i + 1);
        if (!isCompleted) {
          return false;
        }
      }
      return step.id <= this.lastCompletedId + 1;
    },
    isStepCompleted(step) {
      const isCompleted = !!this.completed.find(stepC => stepC.id === step.id);
      return !this.isStepActived(step) && isCompleted;
    },
  },
  watch: {
    actual() {
      if (this.actual <= this.steps.length) {
        this.progress = (100 / (this.steps.length - 1)) * (this.actual - 1);
      }
      return 100;
    },
  },
  computed: {
    lastCompletedId() {
      return this.completed && this.completed.length && this.completed[this.completed.length - 1].id;
    },
  },
};
</script>

<style lang="scss">
.hs-stepper__progress-wrapper {
  top: 10px;
}
.hs-stepper__progress {
  z-index: -1;
}
.progress-bar {
  background-color: #7427f1;
}
</style>
