import i18nHelper from '@/shared/helpers/i18n';

const $t = i18nHelper.$t;

const optionsToQuestion1 = [
  {
    title: 'sign_up.questionary.question1.option1',
    value: 'only_me',
  },
  {
    title: 'sign_up.questionary.question1.option2',
    value: 'me_and_one_more',
  },
  {
    title: 'sign_up.questionary.question1.option3',
    value: 'more_than_two',
  },
];
const optionsToQuestion2 = [
  { title: 'sign_up.questionary.question2.option1', value: 'false' },
  {
    title: 'sign_up.questionary.question2.option2',
    value: 'true',
  },
];
const options1ToQuestion3 = [
  {
    title: 'sign_up.questionary.question3-1.option1',
    value: 'starter',
  },
  {
    title: 'sign_up.questionary.question3-1.option2',
    value: 'ideation',
  },
  {
    title: 'sign_up.questionary.question3-1.option3',
    value: 'launch',
  },
];
const options2ToQuestion3 = [
  {
    title: 'sign_up.questionary.question3-2.option1',
    value: 'seller',
  },
  {
    title: 'sign_up.questionary.question3-2.option2',
    value: 'highSeller',
  },
];
const optionsToQuestion4 = [
  {
    title: 'sign_up.questionary.question4.option1',
    value: 'producer',
  },
  {
    title: 'sign_up.questionary.question4.option2',
    value: 'agency',
  },
  {
    title: 'sign_up.questionary.question4.option3',
    value: 'afiliate',
  },
  {
    title: 'sign_up.questionary.question4.option4',
    value: 'starter',
  },
];
const optionsToQuestion5 = [
  {
    title: 'sign_up.questionary.question5.option1',
    value: 'ebook',
  },
  {
    title: 'sign_up.questionary.question5.option2',
    value: 'onlineCourse',
  },
  {
    title: 'sign_up.questionary.question5.option3',
    value: 'liveStream',
  },
  {
    title: 'sign_up.questionary.question5.option4',
    value: 'download',
  },
  {
    title: 'sign_up.questionary.question5.option5',
    value: 'onlineService',
  },
  {
    title: 'sign_up.questionary.question5.option6',
    value: 'idk',
  },
];
const optionsToQuestion6 = [
  {
    title: 'sign_up.questionary.question6.option1',
    value: 'directLink',
  },
  {
    title: 'sign_up.questionary.question6.option2',
    value: 'audience',
  },
  {
    title: 'sign_up.questionary.question6.option3',
    value: 'ads',
  },
  {
    title: 'sign_up.questionary.question6.option4',
    value: 'idk',
  },
];

function getQuestions() {
  return [
    {
      value: 'teamSize',
      selectedOption: '',
      text: $t('sign_up.questionary.question1.title'),
      options: buildStandartOptions(optionsToQuestion1),
    },
    {
      value: 'sellCourse',
      selectedOption: '',
      text: $t('sign_up.questionary.question2.title'),
      options: buildStandartOptions(optionsToQuestion2),
    },
    {
      value: 'status',
      selectedOption: '',
      text: $t('sign_up.questionary.question3-1.title'),
      options: buildStandartOptions(options1ToQuestion3),
    },
    {
      value: 'profile',
      selectedOption: '',
      text: $t('sign_up.questionary.question4.title'),
      options: buildStandartOptions(optionsToQuestion4),
    },
    {
      value: 'productType',
      text: $t('sign_up.questionary.question5.title'),
      multiselect: true,
      options: [
        ...buildStandartOptions(optionsToQuestion5),
        {
          id: optionsToQuestion5.length + 1,
          type: 'input',
          title: 'Outro',
          value: 'other',
          inputValue: '',
          inputPlaceholder: $t('sign_up.questionary.question5.option7'),
          selected: false,
        },
      ],
    },
    {
      value: 'sellStrategy',
      text: $t('sign_up.questionary.question6.title'),
      multiselect: true,
      options: [
        ...buildStandartOptions(optionsToQuestion6),
        {
          id: optionsToQuestion6.length + 1,
          type: 'input',
          title: 'Outro',
          value: 'other',
          inputValue: '',
          inputPlaceholder: $t('sign_up.questionary.question6.option5'),
          selected: false,
        },
      ],
    },
  ];
}

function buildStandartOptions(arrayOptions) {
  return arrayOptions.map((opt, index) => ({
    id: index + 1,
    type: 'text',
    title: $t(opt.title),
    value: opt.value,
    selected: false,
  }));
}

function normalizeQuestions(questions) {
  const questionsObj = {};
  for (const question of questions) {
    if (question.multiselect) {
      questionsObj[question.value] = question.options
        .filter(option => option.selected)
        .map(option => {
          if (option.type === 'input') {
            return `${option.inputValue}`;
          }
          return option.value;
        });
    } else if (question.selectedOption) {
      const selectedOpt = question.options.find(option => option.selected);
      if (selectedOpt) {
        if (selectedOpt.type === 'input') {
          questionsObj[question.value] = `${selectedOpt.inputValue}`;
        } else {
          questionsObj[question.value] = selectedOpt.value;
        }
      }
    }
  }
  return questionsObj;
}

export default {
  options1ToQuestion3,
  options2ToQuestion3,
  getQuestions,
  normalizeQuestions,
  buildStandartOptions,
};
