<template>
  <div v-if="value">
    <QuestionaryHeader class="d-block d-sm-none" />
    <div v-if="value && actual === 1" class="d-sm-none">
      <MButton
        :label="$t('sign_up.questionary.next')"
        variant="primary"
        size="md"
        @click="$emit('input', false)"
        class="w-100 d-sm-none"
      />
    </div>
    <div class="hs-questionary__initial-bg d-sm-none" :style="initialBGStyle"></div>
  </div>
</template>

<script>
import QuestionaryHeader from './QuestionaryHeader.vue';
import MButton from '@/shared/components/MButton.vue';

export default {
  components: { QuestionaryHeader, MButton },
  props: {
    value: Boolean,
    actual: Number,
    illustration: String,
  },
  data() {
    return {
      initialBGStyle: {
        backgroundImage: `url(${this.illustration}), url(${require('@/assets/images/login/login_shape_vector.svg')})`,
      },
    };
  },
};
</script>
