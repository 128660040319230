<template>
  <div>
    <div class="w-100 d-flex justify-content-end px-xs-0 py-3">
      <Stepper
        @jump="goTo"
        :steps="steps"
        :completed="completedSteps"
        :actual="actual"
        class="col-xs-12 col-sm-8 px-0"
      />
    </div>
    <div class="col-xs-12">
      <transition :name="transition" mode="out-in">
        <QuestionWrapper
          v-if="actual !== questions.length + 1"
          :key="`question${actual}`"
          :question="questions[actual - 1]"
          :actual-step="actual"
          @selected="onOptionSelected"
        />
        <QuestionaryFinished
          @next="onGoNext"
          v-if="actual === questions.length + 1"
          :key="`question${questions.length + 1}`"
        />
      </transition>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-2 mb-6 mb-sm-0">
      <a
        role="button"
        v-if="actual > 1"
        class="hs-questionary__btn-back pointer btn py-3 pr-4 pl-0 text-primary"
        @click="goTo(actual - 1)"
      >
        <hs-icon icon="arrow-left" class="mr-2"></hs-icon>
        {{ $t('sign_up.questionary.back') }}
      </a>
      <hs-button
        class="col-xs-12 col-sm-auto"
        variant="primary"
        data-testid="questionary-next-button"
        @click="onGoNext"
        v-if="showNextButton && hasOptionsSelected"
        :disabled="!hasInputOptionFilled"
      >
        {{ $t('sign_up.questionary.next') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import Stepper from '@/components/Stepper/index';
import QuestionWrapper from './QuestionWrapper.vue';
import QuestionaryFinished from './QuestionaryFinished.vue';
import questionaryService from '@/services/questionary';

export default {
  props: {
    steps: Array,
    actual: Number,
    questions: Array,
  },
  data() {
    return {
      transition: 'slide-left-right-fade',
      wasFinished: false,
    };
  },
  components: { Stepper, QuestionWrapper, QuestionaryFinished },
  methods: {
    onOptionSelected(payload) {
      const showNextButton = this.showNextButton && (this.isMultiselect || payload.option.type === 'input');
      this.$emit('selected', {
        ...payload,
        isMultiselect: this.isMultiselect,
        showNextButton: showNextButton,
      });
    },
    goTo(step) {
      if (this.actual > this.questions.length) {
        this.wasFinished = true;
      }
      this.$emit('step', step);
    },
    onGoNext() {
      this.goTo(this.actual + 1);
    },
  },
  watch: {
    actual(newValue, oldValue) {
      if (newValue < oldValue) {
        this.transition = 'slide-right-left-fade';
      } else {
        this.transition = 'slide-left-right-fade';
      }
    },
  },
  computed: {
    isMultiselect() {
      if (this.actual <= this.questions.length) {
        return this.questions[this.actual - 1].multiselect;
      }
      return false;
    },
    showNextButton() {
      if (this.actual <= this.questions.length) {
        return this.isMultiselect || this.questions[this.actual - 1].showNextButton;
      }
      return false;
    },
    hasOptionsSelected() {
      const optionsSelected = this.questions[this.actual - 1].options.filter(opt => opt.selected);

      return !!optionsSelected.length;
    },
    hasInputOptionFilled() {
      const optionsSelected = this.questions[this.actual - 1].options.filter(opt => opt.selected);
      const inputOption = optionsSelected.find(opt => opt.type === 'input');
      if (inputOption) {
        if (!inputOption.inputValue) {
          return false;
        }
      }
      return true;
    },
    completedSteps() {
      const normalized = questionaryService.normalizeQuestions(this.questions);
      const stepsCompleted = this.questions
        .map((question, index) => ({
          id: index + 1,
          question,
        }))
        .filter(({ question }) => {
          const selected = normalized[question.value];
          return selected && ((!Array.isArray(selected) && selected) || selected.length);
        });
      return stepsCompleted.filter(({ question }, index) => {
        const isCompletedOrActual = this.actual <= index + 1;
        const isLastCompleted = index === stepsCompleted.length - 1;
        const isLastQuestion = index + 1 === this.questions.length;
        if (question.multiselect && isLastCompleted && isCompletedOrActual && !(this.wasFinished && isLastQuestion)) {
          return false;
        }
        return true;
      });
    },
  },
};
</script>

<style scoped>
.slide-left-right-fade-enter-active,
.slide-right-left-fade-enter-active {
  transition: all 0.25s ease;
}

.slide-left-right-fade-leave-active,
.slide-right-left-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-left-right-fade-enter,
.slide-right-left-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
.slide-left-right-fade-leave-to,
.slide-right-left-fade-enter {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
